import fetchJson from '../../lib/fetchApi';
import { clearAuthToken, getAuthToken } from '../util/localStorage';

export const isAuthenticated = () => {
  // Check if the user is authenticated (you can implement your own logic)
  // For example, you can check for the presence of a user token in localStorage
  const userToken = getAuthToken();
  return !!userToken;
};

export const logOut = async () => {
  const { error, data }: { error: boolean; data: string } = await fetchJson(
    `/api/logout`
  );
  if (!error) {
    // Log the user out (you can implement your own logout logic)
    // For example, you can remove the user token from localStorage
    clearAuthToken();
  }
};
