import React from 'react';
import Link from 'next/link';
import {
  Flex,
  Box,
  Text,
  UnorderedList,
  ListItem,
  Heading,
  useMediaQuery,
} from '@chakra-ui/react';
import moment from 'moment';

const Footer = () => {
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');

  return (
    <Flex direction={'column'} backgroundColor={'black'}>
      <Flex
        direction={isLargerThan1024 ? 'row' : 'column'}
        justifyContent={'space-around'}
        p={isLargerThan1024 ? 20 : 10}
        pt={0}
      >
        <Box maxW={300} pt={20}>
          <Heading as={'h3'} size="md" color={'whiteAlpha.900'}>
            About Us
          </Heading>
          <Text color={'whiteAlpha.900'}>
            Vert Executive represents a tangible catalyst for transformation.
            This electric service has been established to unite the desire for
            safety, executive travel, and the imperative to minimize the global
            carbon impact. We are in the process of assembling the most
            formidable Tesla fleet, accompanied by seasoned and skilled
            chauffeurs. Our passionate team is dedicated to fostering a more
            environmentally conscious world, under the guidance of a staunch
            advocate for a sustainable, eco-friendly future. Our endeavors will
            serve as an inspiration for others to follow suit, and together, we
            can ensure a brighter tomorrow for future generations.
          </Text>
        </Box>
        <Box pt={20}>
          <Heading as={'h3'} size="md" color={'whiteAlpha.900'}>
            Company
          </Heading>
          <UnorderedList
            color={'whiteAlpha.900'}
            listStyleType={'none'}
            m={0}
            p={0}
            spacing={2}
          >
            <ListItem>
              <Link href="/privacy-policy">Privacy Policy</Link>
            </ListItem>
            <ListItem>
              <Link href="/terms-and-conditions">Terms And Conditions</Link>
            </ListItem>
            <ListItem>
              <Link href="/cancellation-fee-policy-rider">
                Cancellation Fee Policy
              </Link>
            </ListItem>
            <ListItem>
              <Link href="https://vert.eyesafe.london/#/customer/eyesafe">
                Corporate-Events-Tours-Airports
              </Link>
            </ListItem>
            <ListItem>
              <Link href="https://zfrmz.com/CLgmEDkJtdeJF0Qt2afz">
                Drive With Vert
              </Link>
            </ListItem>
            <ListItem>
              <Link href="https://zfrmz.com/yRp3Av4RakgvYAzYDasr">
                Vert For Business
              </Link>
            </ListItem>
          </UnorderedList>
        </Box>
        <Box pt={20}>
          <Heading as={'h3'} size="md" color={'whiteAlpha.900'}>
            Quick Links
          </Heading>
          <UnorderedList
            color={'whiteAlpha.900'}
            listStyleType={'none'}
            m={0}
            p={0}
            spacing={2}
          >
            <ListItem>
              <Link href="/">Home</Link>
            </ListItem>
            <ListItem>
              <Link href="/blog">Blog</Link>
            </ListItem>
            <ListItem>
              <Link href="/faq">FAQ</Link>
            </ListItem>
            <ListItem>
              <Link href="/chauffeur">Chauffeur</Link>
            </ListItem>
            <ListItem>
              <Link href="/affiliate">Affiliate</Link>
            </ListItem>
            <ListItem>
              <Link href="/advertise">Advertise</Link>
            </ListItem>
            <ListItem>
              <Link href="/contact">Contact</Link>
            </ListItem>
            <ListItem>
              <Link href="/locations">Locations</Link>
            </ListItem>
          </UnorderedList>
        </Box>
        <Box pt={20}>
          <Heading as={'h3'} size="md" color={'whiteAlpha.900'}>
            Social Pages
          </Heading>
          <UnorderedList
            color={'whiteAlpha.900'}
            listStyleType={'none'}
            m={0}
            p={0}
            spacing={2}
          >
            <ListItem>
              <Link href="https://www.facebook.com/GoGreenGoVert">
                Facebook
              </Link>
            </ListItem>
            <ListItem>
              <Link href="https://www.instagram.com/vert_london1">
                Instagram
              </Link>
            </ListItem>
            <ListItem>
              <Link href="https://twitter.com/Vert_LDN">x</Link>
            </ListItem>
            <ListItem>
              <Link href="https://www.linkedin.com/company/vert-london">
                Linkedin
              </Link>
            </ListItem>
            <br />
            <ListItem>
              <Heading as={'h3'} size="md" color={'whiteAlpha.900'}>
                Locations
              </Heading>
            </ListItem>
            <ListItem>
              <Link href="?location=London">Chauffeur Service London</Link>
            </ListItem>
            <ListItem>
              <Link href="?location=Essex">Chauffeur Service Essex</Link>
            </ListItem>
            <br />
            <ListItem>
              <Heading as={'h3'} size="md" color={'whiteAlpha.900'}>
                Airport Chauffeur Service
              </Heading>
            </ListItem>
            <ListItem>
              <Link href="?location=HeathrowAirport">
                Chauffeur Service Heathrow Airport
              </Link>
            </ListItem>
            <ListItem>
              <Link href="?location=LondonCityAirport">
                Chauffeur Service London City Airport
              </Link>
            </ListItem>
            <ListItem>
              <Link href="?location=StanstedAirport">
                Chauffeur Service Stansted Airport
              </Link>
            </ListItem>
            <ListItem>
              <Link href="?location=GatwickAirport">
                Chauffeur Service Gatwick Airport
              </Link>
            </ListItem>
            <ListItem>
              <Link href="?location=SouthendAirport">
                Chauffeur Service Southend Airport
              </Link>
            </ListItem>
          </UnorderedList>
        </Box>
      </Flex>
      <Box textAlign={'center'}>
        <Text color={'whiteAlpha.900'}>
          &copy;{moment().format('YYYY')} @ Vert London TfL Licence 01042901
        </Text>
      </Box>
    </Flex>
  );
};

export default Footer;
