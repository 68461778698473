import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { logOut } from '../../services/auth/AuthService';
import Link from 'next/link';
import Image from 'next/image';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { getAuthToken } from '../../services/util/localStorage';
import fetchJson from '../../lib/fetchApi';
import { useQuery } from '@apollo/client';
import { GET_CLIENT } from '../../apollo/queries/GetClient';
import { GlobalContext } from '../../pages/_app';

type User = {
  id: number;
  type: string;
};

const AppNavBar = () => {
  const [countNotifications, setCountNotificatiions] = useState(0);
  const [user, setUser] = useState<User>({
    id: 0,
    type: '',
  });
  const [globalData, setGlobalData] = useContext(GlobalContext);
  const router = useRouter();
  const token = getAuthToken();

  let { data, loading, error } = useQuery(GET_CLIENT, {
    context: { clientName: 'vert' },
  });

  useEffect(() => {
    if (data) {
      setUser(data.getClient);
      setGlobalData({ ...globalData, user: data.getClient });
      getNotifications();
    }
  }, [data, loading, error]);

  const getNotifications = () => {
    const headers = {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(
        `https://dev.vert.london/notifications?user_id=${user.id}&type=client`,
        {
          headers,
        }
      )
      .then(({ data }) => {
        if (!data.error) {
          const unreadNotificaitons = data.notifications.filter(
            (item) => item.is_read == 0
          );
          setCountNotificatiions(unreadNotificaitons.length);
        }
      });
  };

  const onSignOut = () => {
    logOut();
    router.push(`/`);
  };

  return (
    <Flex
      h={'80px'}
      position={'fixed'}
      backgroundColor={'brand.900'}
      w={'100%'}
      shadow={'md'}
      px={5}
      alignItems={'center'}
      zIndex={3}
    >
      <Flex w={'100%'} justify={'space-between'}>
        <Link
          href="/"
          style={{
            marginTop: 5,
          }}
        >
          <Image
            style={{ cursor: 'pointer' }}
            src={'/images/logo.png'}
            alt="logo"
            height={25}
            width={55}
          />
        </Link>
        <Box>
          <Menu arrowPadding={5}>
            <MenuButton as={Button} colorScheme={'transparent'}>
              <GiHamburgerMenu size={20} color="white" />
              {countNotifications !== 0 && (
                <Box
                  rounded={50}
                  backgroundColor={'red.500'}
                  w={'13px'}
                  h={'13px'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  left={7}
                  top={2}
                  position={'absolute'}
                />
              )}
            </MenuButton>
            <MenuList>
              <MenuGroup m={3} title="Profile">
                <MenuItem
                  fontSize={14}
                  onClick={() => router.push('/app/account')}
                >
                  My Account
                </MenuItem>
                <MenuItem
                  fontSize={14}
                  onClick={() => router.push('/app/notifications')}
                >
                  Notifications{' '}
                  {countNotifications !== 0 && (
                    <Box
                      rounded={50}
                      backgroundColor={'red.500'}
                      minW={'20px'}
                      h={'20px'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      mx={1}
                    >
                      <Text
                        m={0}
                        fontSize={10}
                        color={'white'}
                        fontWeight={'semibold'}
                        textAlign={'center'}
                        p={'2.5px'}
                      >
                        {countNotifications}
                      </Text>
                    </Box>
                  )}
                </MenuItem>
              </MenuGroup>
              <MenuDivider />
              <MenuGroup m={3} title="Help">
                <MenuItem
                  fontSize={14}
                  onClick={() => router.push('/app/tutorials')}
                >
                  Tutorials
                </MenuItem>
                <MenuItem fontSize={14} onClick={() => router.push('/faq')}>
                  FAQ
                </MenuItem>
                <MenuItem fontSize={14} onClick={() => router.push('/contact')}>
                  Contact
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  fontSize={14}
                  color={'red.500'}
                  fontWeight={'semibold'}
                  onClick={() => onSignOut()}
                >
                  Log Out
                </MenuItem>
              </MenuGroup>
            </MenuList>
          </Menu>
        </Box>
      </Flex>
    </Flex>
  );
};

export default AppNavBar;
